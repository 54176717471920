<template>
  <page-content :show-search="true">
    <template #search>
      <table-search
        ref="search"
        :fields="search_fields"
        @runSearch="runSearch"
      />
    </template>

    <template #default>
      <table-header
        ref="header"
        :title="title"
        :button-actions="header_actions"
        @runSearch="runSearch"
        :search-tips="$t('company.search_tips')"
      />

      <table-content
        :per-page="page_condition.pageSize"
        :per-page-dropdown-enabled="true"
        @perPageChange="perPageChange"
        :columns="columns"
        :rows="rows"
        :total-rows="total_rows"
        :current-page="current_page"
        :action-options="table_actions"
        @runTableAction="runTableAction"
        @sortChange="sortChange"
        @pageChange="pageChange"
        :permission="view_permission"
      />
    </template>

  </page-content>
</template>

<script>
import common from "@/common";
import TableContent from "@/components/TableContent";
import TableHeader from "@/components/TableHeader";
import TableSearch from "@/components/TableSearch";
import PageContent from "@/components/PageContent";

export default {
  components: {
    PageContent,
    TableSearch,
    TableHeader,
    TableContent,
  },
  data() {
    return {
      title: common.getMenuName('company'),
      view_permission: common.checkPermission('Com_List'),
      rows: [],
      total_rows: 0,
      sort_condition: {},
      current_page: 1,
      page_condition: {
        pageIndex: 0,
        pageSize: common.getTablePerPage()
      },
      columns: [
        {
          label: 'common.row_index',
          field: 'row_index',
          sortable: false,
          width: '50px',
        },
        {
          label: 'company.companyCode',
          field: 'companyCode',
        },
        {
          label: 'company.chineseName',
          field: 'chineseName',
        },
        {
          label: 'company.englishName',
          field: 'englishName',
        },
        {
          label: 'company.contractEndDay',
          field: 'contractEndDay',
          formatFn: common.getDateFormat,
        },
        {
          label: 'common.action',
          field: 'action',
          width: '200px',
          sortable: false,
        },
      ],
      header_actions: [
        {
          text: 'common.add',
          variant: 'primary',
          fun: this.addData,
          permission: 'Com_Create'
        },
      ],
      table_actions: [
        {
          text: 'common.edit',
          variant: 'primary',
          fun: 'editData',
          permission: 'Com_View'
        },
        {
          text: 'common.delete',
          variant: 'danger',
          fun: 'deleteData',
          permission: 'Com_Delete'
        }
      ],
      search_fields: [
        {
          field: 'contract',
          label: 'company.search_contract',
          type: 'select',
          options: [
            {text: this.$t('company.search_contract_expired'), value: 0},
            {text: this.$t('company.search_contract_one_month'), value: 1},
            {text: this.$t('company.search_contract_three_month'), value: 2},
          ]
        },
        {
          field: 'isSingleFacility',
          label: 'company.isSingleFacility',
          type: 'select',
          options: [
            {text: this.$t('company.single_facility'), value: true},
            {text: this.$t('company.multi_facility'), value: false},
          ]
        },

      ]
    }
  },
  mounted() {
    if (!this.view_permission) return
    this.getList()
  },

  methods: {
    getList: function () {
      const api = '/company/GetCompanyList'
      const search = this.getSearchCondition()

      const url = common.getTableUrl(api, search, this.sort_condition, this.page_condition)

      common.apiGetData(url).then(res => {
        this.total_rows = res.data.total
        this.rows = res.data.companys
      })
    },
    perPageChange(size) {
      this.page_condition.pageIndex = 0
      this.page_condition.pageSize = size
      common.setTablePerPage(size)
      this.getList()
    },
    addData: function () {
      this.$router.push({name: 'company_add'})
    },
    editData: function (data) {
      this.$router.push({name: 'company_edit', params: {id: common.encrypt(data.companyID)}})
    },
    deleteData: function (data) {
      const that = this
      common.showConfirm(this.$t('common.delete_tips')+data.chineseName, '', function () {
        common.apiPostData('/company/DeleteCompany?companyID='+data.companyID).then(res => {
          common.showToast(that.$t('common.delete_success'))
          that.getList()
        })

      })
    },
    runSearch: function () {
      this.current_page = 1
      this.page_condition.pageIndex = 0
      this.getList()
    },
    runTableAction: function (action, data) {
      this[action](data)
    },
    sortChange: function (value) {
      this.sort_condition = value
      this.getList()
    },
    getSearchCondition: function () {
      this.$refs.search.buildSearchCondition()

      let where = {}
      where['searchContent'] = this.$refs.header.searchContent
      where = Object.assign(where, this.$refs.search.search_condition)

      return where
    },
    pageChange: function (value) {
      this.current_page = value
      this.page_condition.pageIndex = value - 1
      this.getList()
    },
  }
}
</script>
